module services {
    export module master {
        export class transportTypeService implements interfaces.master.ITransportTypeService {
            static $inject = ["$resource", "ENV"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }

            getDropdownList(ownerEntityId: number, searchText: string): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {

                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "TransportType/GetDropdownList", {
                    ownerEntityId: ownerEntityId,
                    searchText: searchText
                });
            }
        }
    }
    angular.module("app").service("transportTypeService", services.master.transportTypeService);
}